import { enableClient } from "@wix/cli-app/internal/platform-sdk/site";
(async function () {
  if (!window['React']) {
    await import('https://unpkg.com/react@16.14.0/umd/react.production.min.js');
    await import('https://unpkg.com/react-dom@16.14.0/umd/react-dom.production.min.js');
  }
  const { default: UsersCustomElementClass } = await import('/src/site/widgets/custom-elements/calc-widget/element.tsx');

  let accessTokenListenerFn = UsersCustomElementClass.prototype.accessTokenListener;
  if (!accessTokenListenerFn) {
    const { injectGetAccessToken } = enableClient('0ccfef19-96da-4112-af03-abbe5d812f29');
    accessTokenListenerFn = injectGetAccessToken;
  }
  Object.defineProperty(UsersCustomElementClass.prototype, 'accessTokenListener', {
    writable: false,
    configurable: false,
    value: accessTokenListenerFn
  });

  customElements.define('calc-widget', UsersCustomElementClass);
})();